.fm-zone-changes-item {
  display: flex;
  align-items: center;
  margin: 20px 20px 25px 20px;
  gap: 12px;
}

.fm-zone-changes-item-name {
  width: 260px;
  line-height: 24px;
}

.fm-zone-changes-item-id {
  color: $light-text-color;
  line-height: 24px;
  font-size: 12px;
  margin-left: 3px;
}

.fm-zone-changes-item-field {
  background: $light-background-color;
  line-height: 24px;
  padding: 0 8px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 3px;
}

.fm-zone-changes-item-value {
  background: $light-background-color;
  line-height: 24px;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 3px;
}

.fm-zone-changes-item-date {
  flex: 1;
  text-align: right;
  color: #999;
  font-size: 14px;
  line-height: 24px;
}

@media (prefers-color-scheme: dark) {
  .fm-zone-changes-item-value, .fm-zone-changes-item-field {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
}
