.fm-analytics-complaints {
  padding: 15px;
}

.fm-analytics-complaints-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #ff206e;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  width: 150px;
  margin-left: 15px;
}

.fm-analytics-complaints-form {
  display: flex;
  margin: 0 0 15px 0;
}

table.fm-analytics-complaints-table {
  width: 100%;
  table-layout: fixed;
}

td.fm-analytics-complaints-table-label {
  padding: 10px 0;
}

td.fm-analytics-complaints-table-value {
  padding: 10px 0;
  text-align: right;
}

.fm-analytics-complaints-table td {
  border-bottom: 1px solid #eee;
}

.fm-analytics-complaints-empty {
  color: #999;
  text-align: center;
  font-size: 14px;
  margin-top: 100px;
}

td.fm-analytics-complaints-table-item {
  vertical-align: top;
}

p.fm-analytics-complaints-table-subject {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

p.fm-analytics-complaints-table-name {
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
}

p.fm-analytics-complaints-table-date {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

p.fm-analytics-complaints-table-region {
  font-size: 14px;
  line-height: 20px;
  color: $light-text-color;
}

p.fm-analytics-complaints-table-content {
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p.fm-analytics-complaints-table-duration {
  font-size: 14px;
  line-height: 20px;
}

.fm-analytics-complaints-table-item.fm-analytics-complaints-table-item-contact {
  width: 210px;
  padding: 10px 0;
}

.fm-analytics-complaints-table-item.fm-analytics-complaints-table-item-details {
  width: 120px;
  padding: 10px 0;
}

.fm-analytics-complaints-table-item.fm-analytics-complaints-table-item-duration {
  width: 100px;
  padding: 10px 0;
}

.fm-analytics-complaints-table-item.fm-analytics-complaints-table-item-content {
  vertical-align: middle;
}
