.fm-zone-view-details-map {
  height: 300px;
}

.fm-zone-view-edit {
  padding: 0 20px 30px 20px;
}

.fm-zone-view-input {
  margin: 20px 0 25px 0;
}

.fm-zone-view-details-items {
  margin: 20px;
}

.fm-zone-view-details-type {
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 800;
  line-height: 20px;
  margin: 4px 0;
  padding: 3px 6px;
}

.fm-zone-view-details-key {
  font-weight: 600;
}

.fm-zone-view-details-value {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}

.fm-zone-view-details-message {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}

.fm-zone-view-details-changes-title {
  font-size: 15px;
  font-weight: 600;
  margin: 30px 20px 25px 20px;
}
