.fm-document-template-content {
  display: flex;
  margin: 20px;
  gap: 25px;
}

.fm-document-template-side {
  flex: 1;
}

.fm-document-template-details {
  flex: 1;
  background: $light-background-color;
  border-radius: 5px;
  white-space: pre-wrap;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
}

.fm-document-template-side-generate-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}

.fm-document-template-side-versions-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}

.fm-document-template-side-versions {
  margin-top: 30px;
}

.fm-document-template-item-subtitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.fm-document-templates-item-status {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin: 4px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

.fm-document-templates-item-created {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin: 4px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

.fm-document-templates-item-details {
  display: flex;
  gap: 10px;
}

.fm-document-template-item {
  display: block;
  margin: 10px 0 20px 0;
}

.fm-document-template-side-generate-button {
  background: #222;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  transition: all .1s ease;
  padding: 0 20px;
}

.fm-document-template-side-generate-form {
  display: flex;
  margin: 10px 0;
  gap: 10px;
}

.fm-document-template-side-generate-notes {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  margin: 15px 0 0 0;
  line-height: 20px;
}
