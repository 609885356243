.fm-zone-search-input {
  margin: 15px;
}

.fm-zone-search-item {
  display: flex;
  align-items: center;
  margin: 20px 20px 25px 20px;
  gap: 18px;
}

.fm-zone-search-item-status {
  background: #4cd137;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.fm-zone-search-item-status-inactive {
  background: #e84118;
}

.fm-zone-search-item-name {
  flex: 1;
}

.fm-zone-search-item-type {
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  font-weight: 800;
  margin: 4px 0;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
}
