.fm-zone-polygon {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-zone-polygon-main {
  flex: 1 1;
  display: flex;
}

.fm-zone-polygon-map {
  flex: 1 1;
  position: relative;
}
